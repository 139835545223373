import OilinessLocationTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-oiliness/oiliness-location/OilinessLocationTemplate';

import { GENDER, GREASY_SKIN_LOCATION } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/SkinOiliness/OilinessLocation/OilinessLocationTemplate',
  component: OilinessLocationTemplate
};

const createStory = props => () => ({
  components: { OilinessLocationTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><oiliness-location-template v-bind="$options.storyProps" /></div>'
});

export const Base = createStory({
  gender: GENDER.MALE,
  greasySkinLocations: GREASY_SKIN_LOCATION.TZONE_ONLY
});
